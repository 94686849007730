import messages from './mslc-lang.json';
export default {
	i18n: {
		messages
	},
	data() {
		const vm = this;
		return {
			slidesPerView: 0,
			slidesLength: 0,
			swiperOptions: {
				a11y: {
					prevSlideMessage: this.$t('previousPage'),
					nextSlideMessage: this.$t('nextPage'),
					firstSlideMessage: this.$t('firstPage'),
					lastSlideMessage: this.$t('lastPage'),
					paginationBulletMessage: this.$t('jumpToPage'),
					slideLabelMessage: this.$t('slideItemLabel')
				},
				slidesPerView: 1,
				spaceBetween: 16,
				breakpoints: {
					768: {
						slidesPerView: 3,
						spaceBetween: 16
					},
					996: {
						slidesPerView: 3,
						spaceBetween: 32
					}
				},
				on: {
					breakpoint(swiper, { slidesPerView }) {
						vm.slidesPerView = slidesPerView;
					},
					init(swiper) {
						vm.slidesLength = swiper.slides.length;
					},
					slidesUpdated(swiper) {
						vm.slidesLength = swiper.slides.length;
					}
				}
			}
		};
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		},
		hasPager() {
			return this.slidesLength > this.slidesPerView;
		}
	},
	mounted() {
		const swiperEl = this.$refs.swiper;
		const navigationOptions = {
			pagination: {
				el: `.mslc__pagination__${swiperEl.id}`,
				clickable: true
			},
			navigation: {
				nextEl: `.mslc__button-next__${swiperEl.id}`,
				prevEl: `.mslc__button-prev__${swiperEl.id}`
			}
		};
		Object.assign(swiperEl, this.swiperOptions, navigationOptions);
		swiperEl.initialize();
	}
};
